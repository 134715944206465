exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-artiklid-tsx": () => import("./../../../src/pages/artiklid.tsx" /* webpackChunkName: "component---src-pages-artiklid-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lepingud-tsx": () => import("./../../../src/pages/lepingud.tsx" /* webpackChunkName: "component---src-pages-lepingud-tsx" */),
  "component---src-pages-liitu-tsx": () => import("./../../../src/pages/liitu.tsx" /* webpackChunkName: "component---src-pages-liitu-tsx" */),
  "component---src-pages-meist-index-tsx": () => import("./../../../src/pages/meist/index.tsx" /* webpackChunkName: "component---src-pages-meist-index-tsx" */),
  "component---src-pages-meist-juhtkond-tsx": () => import("./../../../src/pages/meist/juhtkond.tsx" /* webpackChunkName: "component---src-pages-meist-juhtkond-tsx" */),
  "component---src-pages-meist-metsaspetsialistid-tsx": () => import("./../../../src/pages/meist/metsaspetsialistid.tsx" /* webpackChunkName: "component---src-pages-meist-metsaspetsialistid-tsx" */),
  "component---src-pages-metsaomanikule-tsx": () => import("./../../../src/pages/metsaomanikule.tsx" /* webpackChunkName: "component---src-pages-metsaomanikule-tsx" */),
  "component---src-pages-paringud-tsx": () => import("./../../../src/pages/paringud.tsx" /* webpackChunkName: "component---src-pages-paringud-tsx" */),
  "component---src-pages-teenused-metsa-hindamine-tsx": () => import("./../../../src/pages/teenused/metsa-hindamine.tsx" /* webpackChunkName: "component---src-pages-teenused-metsa-hindamine-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-dynamic-page-tsx": () => import("./../../../src/templates/dynamicPage.tsx" /* webpackChunkName: "component---src-templates-dynamic-page-tsx" */),
  "component---src-templates-juhend-tsx": () => import("./../../../src/templates/juhend.tsx" /* webpackChunkName: "component---src-templates-juhend-tsx" */),
  "component---src-templates-kkk-tsx": () => import("./../../../src/templates/kkk.tsx" /* webpackChunkName: "component---src-templates-kkk-tsx" */),
  "component---src-templates-metsaspetsialist-tsx": () => import("./../../../src/templates/metsaspetsialist.tsx" /* webpackChunkName: "component---src-templates-metsaspetsialist-tsx" */),
  "component---src-templates-paring-tsx": () => import("./../../../src/templates/paring.tsx" /* webpackChunkName: "component---src-templates-paring-tsx" */)
}

